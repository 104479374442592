<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('payable_cheques.create')" to="/cheques-cycle/payable-cheques/create" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('payable_cheques.add_payable_cheques')}}
            </router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>
        
        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row mb-5">
                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label>{{$t('payable_cheques.bank')}}</label>
                            <multiselect v-model="bank"
                                :placeholder="$t('payable_cheques.bank')"
                                label="name"
                                track-by="id"
                                :options="banks"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getBanks($event)">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label>{{$t('payable_cheques.added_by')}}</label>
                            <multiselect v-model="user"
                                :placeholder="$t('payable_cheques.added_by')"
                                label="name"
                                track-by="id"
                                :options="users"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getUsers($event)">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-4 mt-2 mb-2">
                            <label for="booking_no">{{$t('payable_cheques.booking_no')}}</label>
                            <input v-model="filters.booking_no" type="number" id="booking_no" class="form-control">
                        </div>
                        <div class="form-group col-md-3 mt-2 mb-2">
                            <label for="from_issue_date">{{$t('payable_cheques.from_issue_date')}}</label>
                            <input v-model="filters.from_issue_date" type="date" id="from_issue_date" class="form-control">
                        </div>
                        <div class="form-group col-md-3 mt-2 mb-2">
                            <label for="to_issue_date">{{$t('payable_cheques.to_issue_date')}}</label>
                            <input v-model="filters.to_issue_date" type="date" id="to_issue_date" class="form-control">
                        </div>
                        <div class="form-group col-md-3 mt-2 mb-2">
                            <label for="from_due_date">{{$t('payable_cheques.from_due_date')}}</label>
                            <input v-model="filters.from_due_date" type="date" id="from_due_date" class="form-control">
                        </div>
                        <div class="form-group col-md-3 mt-2 mb-2">
                            <label for="to_due_date">{{$t('payable_cheques.to_due_date')}}</label>
                            <input v-model="filters.to_due_date" type="date" id="to_due_date" class="form-control">
                        </div>
                        <div class="form-group col-md-3 mt-2 mb-2">
                            <label>{{$t('payable_cheques.currency')}}</label>
                            <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                                <option v-for="row in currencies" :value="row.id" :key="row.id">
                                    {{ row.name }}
                                </option>
                            </select>
                        </div>
                        
                        <div class="form-group col-md-3 mt-2 mb-2">
                            <label for="f_status">{{$t('status')}}</label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3 mt-2 mb-2">
                            <label for="cheques_no">{{$t('payable_cheques.cheques_no')}}</label>
                            <input v-model="filters.cheques_no" type="number" id="cheques_no" class="form-control">
                        </div>


                        <div class="form-group d-inline-flex col-md-3 mt-6 mb-0">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::customer-->
        <div class="card card-custom">
           
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <!--                -->
                        <button v-if="$can('payable_cheques.change_status')" @click="changeStatus(props.row.id, props.row.status, props.row)" class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>
                        <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>

                    </template>
                    <!--            -->
                    <template slot="actions" slot-scope="props">
                        <v-btn v-if="$can('payable_cheques.update')" icon color="pink" :to="`/cheques-cycle/payable-cheques/edit/${props.row.id}`">
                            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2">mdi-pencil</v-icon>
                        </v-btn>
                        <!--               -->
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('payable_cheques.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                        <v-btn icon color="pink" :to="`/cheques-cycle/payable-cheques/view/${props.row.id}`">
                            <v-icon small class="mr-2">mdi-eye</v-icon>
                        </v-btn>
                        <b-dropdown v-if="$can('payable_cheques.send_mail')" id="dropdown-offset" :text="$t('more_actions')" variant="outline-primary" class="m-2">
                            <router-link class="dropdown-item" :to="`/settings/custom/email/${props.row.id}/PayableCheque`">
                                {{ $t('send_mail') }}
                            </router-link>
                        </b-dropdown>
                    </template>
                </v-server-table>

            </div>
        </div>
        <!--end::customer-->
        <b-modal ref="status-modal" hide-footer :title="$t('change_status')">
            <change-status-form @hide-modal="hideModal()"
                                @handling-status="returnHandlingStatus"
                                :status-list="status_list"
                                :url="routeChangeStatus"
                                :current-id="innerId"
                                :current-status="statusId"
                                @handling-data="getDataAfterChangeStatus">
            </change-status-form>
        </b-modal>
        <b-modal ref="collect-modal" hide-footer :title="$t('Collect')">
            <collect-payable-sheet-form @hide-modal="hideCollectModal()"
                                        :current-id="innerId"
                                        :current-status="statusId"
                                        :payable-object="payable_object"
                                        @handling-data="getCollectDataAfterChangeStatus">
            </collect-payable-sheet-form>
        </b-modal>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";
    import CollectPayableSheetForm from "@/view/content/forms/CollectPayableSheetForm";

    export default {
        name: "index-cheques-payable-cheques",
        components: {'change-status-form': ChangeStatusFrom, 'collect-payable-sheet-form': CollectPayableSheetForm,},
        data() {
            return {
                mainRoute: 'chequescycle/payable-cheques',
                routeChangeStatus: 'chequescycle/payable-cheque/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    booking_no: null,
                    bank_id: null,
                    from_issue_date: null,
                    to_issue_date: null,
                    from_due_date: null,
                    to_due_date: null,
                    currency_id: null,
                    user_id: null,
                    status: null,
                    cheques_no: null,
                },
                status_list: [],
                columns: ['amount', 'book_number', 'cheques_no', 'bank_name', 'currency_name', 'received_account', 'cheques_name', 'issue_date', 'due_date', 'status', 'actions'],
                data: [],
                currencies: [],
                banks: [],
                users: [],
                innerId: null,
                statusId: null,
                payable_object: [],
                bank: null,
                user: null,
            }
        },

        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        amount: that.$t('payable_cheques.amount'),
                        book_number: that.$t('payable_cheques.book_number'),
                        cheques_no: that.$t('payable_cheques.cheques_no'),
                        bank_name: that.$t('payable_cheques.bank'),
                        currency_name: that.$t('payable_cheques.currency'),
                        received_account: that.$t('payable_cheques.received_account'),
                        cheques_name: that.$t('payable_cheques.cheques_name'),
                        issue_date: that.$t('payable_cheques.issue_date'),
                        due_date: that.$t('payable_cheques.due_date'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable:false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params})

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        watch:{
            statusId: function (val) {
                if (val){
                    if (val == 3){
                        this.hideModal();
                        this.showCollectModal();
                    }
                }
            },
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
            bank: function (val) {
                if (val) {
                    this.filters.bank_id = val.id;
                } else {
                    this.filters.bank_id = null;
                }
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.payable_cheques")}]);
            this.getCurrencies();
            this.getStatusList();
        },
        methods: {
            // changeStatus(id, status) {
            //     ApiService.patch(this.routeChangeStatus + '/' + id, {
            //         status: (status ? 1 : 0),
            //     }).then((response) => {
            //         this.$refs.table.refresh();
            //         this.$successAlert(response.data.message);
            //     }).catch(error => {
            //         this.$errorAlert(error);
            //     });
            // },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.booking_no = null;
                this.filters.bank_id = null;
                this.filters.from_issue_date = null;
                this.filters.to_issue_date = null;
                this.filters.from_due_date = null;
                this.filters.to_due_date = null;
                this.filters.currency_id = null;
                this.filters.user_id = null;
                this.filters.status = null;
                this.filters.cheques_no = null;
                this.user= null;
                this.bank = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },
            getBanks(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/banks`,{params:{filter: filter}}).then((response) => {
                        this.banks = response.data.data;
                    });
                }else{
                    this.banks = [];
                }
            },

            getUsers(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/users`,{params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
                }else{
                    this.users = [];
                }
            },
            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/cheques_status").then((response) => {
                    this.status_list = response.data.data;
                });
            },
            actionDelete(item) {
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            changeStatus(id, status, payable_object) {
                this.innerId = id;
                this.statusId = status;
                this.payable_object = payable_object;
                this.showModal();
            },
            showModal() {
                this.$refs['status-modal'].show()
            },
            hideModal() {
                this.$refs['status-modal'].hide();
            },
            getDataAfterChangeStatus() {
                this.innerId = null;
                this.statusId = null;
                this.payable_object = {};
                this.doFilter();
            },
            showCollectModal() {
                this.$refs['collect-modal'].show()
            },
            hideCollectModal() {
                this.$refs['collect-modal'].hide();
            },
            getCollectDataAfterChangeStatus() {
                // this.innerId = null;
                // this.statusId = null;
                // this.payable_object = {};
                this.doFilter();
            },
            returnHandlingStatus(status){
                this.statusId = status;
            }
        },
    };
</script>
