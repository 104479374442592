<template>
    <div>
        <!--begin::customer-->
         <div class="form-group row">
                <div class="col-lg-6 mb-5">
                    <p><b>{{$t('payable_cheques.amount')}}: </b> {{show.amount}}</p>
                </div>
                <div class="col-lg-6 mb-5">
                    <p><b>{{$t('payable_cheques.currency')}}: </b> {{show.currency_name}}</p>
                </div>
                <div class="col-lg-6 mb-5">
                    <p><b>{{$t('payable_cheques.currency')}}: </b> {{show.currency_name}}</p>
                </div>
                <div class="col-lg-6 mb-5">
                    <p><b>{{$t('payable_cheques.bank_account')}}: </b> {{account_name}}</p>
                </div>
            </div>
        <div class="form-group row">
            <div class="col-lg-12 mb-5">
                <label>{{$t('payable_cheques.collect_date')}}</label>
                <input type="date" v-model="data.collect_date" class="form-control" :class="validation && validation.collect_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.collect_date" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.collect_date[0] }}
                    </span>
            </div>
            <div class="col-lg-12 mb-5">
                <label>{{$t('payable_cheques.notes')}}</label>
                <textarea v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.notes[0] }}
                    </span>
            </div>
        </div>

        <div class="card-footer pl-0 pr-0 pb-0">
            <div class="row">
                <div class="col-lg-6">
                    <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                    <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
                </div>
            </div>
        </div>

        <!--end::customer-->
    </div>
</template>
<script>
    import ApiService from "@/core/services/api.service";

    export default {
        name: "form-collect-payable-sheetForm",
        props: {
            hideModal: {type: Function},
            handlingData: {type: Function},
            currentId: null,
            currentStatus: null,
            payableObject: {type: Object},

        },
        data() {
            return {
                mainRoute: 'chequescycle/payable-cheques',
                mainRouteDependency: 'base/dependency',
                routeChangeStatus: 'chequescycle/payable-cheque/change-status',
                data: {
                    id: null,
                    status: null,
                    collect_date : null,
                    notes: null,
                },
                show:{},
                account_name:null,
                validation: null,
            };
        },

        methods: {
            save() {
                this.create();
            },
            cancel() {
                this.$emit('hide-modal');
                this.resetAfterSave();
                this.finishedSave();
            },
            finishedSave(su_object) {
                this.$emit('handling-data', su_object);
            },
            create() {
                ApiService.patch(this.routeChangeStatus + '/' + this.data.id, {
                    ...this.data,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.cancel();
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
             getAccountingRoute() {
                 ApiService.get(this.mainRouteDependency + "/accounts_routing",{params:{key: 'payable_cheque_account'}}).then((response) => {
                    this.account_name = response.data.data.account_name;
                });
            },
            resetAfterSave() {
                this.data.id = null;
                this.data.status = null;
                this.data.collect_date = null;
                this.data.notes = null;
            },
        },

        mounted() {
            this.data.id = this.currentId;
            this.data.status = this.currentStatus;
            this.show = this.payableObject;
            if (this.payableObject){
                this.data.collect_date = this.payableObject.collect_date;
                this.data.notes = this.payableObject.notes;
            }else {
                this.data.collect_date = null;
                this.data.notes = null;

            }
            this.getAccountingRoute();
        },
    };
</script>